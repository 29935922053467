<template>
  <div class="Box">
    <div class="limitBox SpecialBox">
      <div v-if="state.specialInfo.home_banner1" @click="SpecialDetails">
        <el-image
          :src="state.specialInfo.home_banner1"
          fit="cover"
          style="width: 100%; height: 140px; display: block"
        >
          <template #error>
            <div class="image-slot">
              <span>加载中</span>
            </div>
          </template>
        </el-image>
      </div>
      <div v-else style="cursor: auto">
        <el-image
          :src="require('../img/culture.png')"
          fit="cover"
          style="width: 100%; height: 140px; display: block"
        >
          <template #error>
            <div class="image-slot">
              <span>加载中</span>
            </div>
          </template>
        </el-image>
      </div>
    </div>

    <div class="limitBox Industry">
      <div class="mainTitle">
        <p>产业信息</p>
        <p>Industry Info</p>
      </div>
    </div>

    <div class="limitBox contentBox">
      <div>
        <div class="partBox">
          <div class="Industry Part">
            <div class="subTitle">
              <p>{{ state.config1.TypeList.typename }}</p>
              <p>{{ state.config1.TypeList.typenameE }}</p>
            </div>
            <div class="DisplayFlex">
              <div></div>
              <div class="SeeMore" @click="SeeMore1">
                <p>
                  <span>查看更多</span
                  ><span class="iconfont iconarrowright"></span>
                </p>
              </div>
            </div>
          </div>
          <div class="TabInfo loading_areaEnter">
            <div
              v-for="(item, index) in state.tabInfo1"
              :key="index"
              class="TabInfoItem"
            >
              <div class="top">
                <div>
                  <el-image
                    v-if="item.logo"
                    :src="item.logo"
                    class="cuImage"
                    fit="cover"
                    style="width: 100px; height: 100px; display: block"
                    @click="GoToDetails(item)"
                  >
                    <template #error>
                      <div class="image-slot">
                        <span>加载中</span>
                      </div>
                    </template>
                  </el-image>
                  <el-image
                    v-else
                    :src="require('../../Home/img/logo.png')"
                    class="cuImage"
                    fit="contain"
                    style="
                      width: 100px;
                      height: 100px;
                      display: block;
                      background-color: white;
                      padding: 10px;
                    "
                    @click="GoToDetails(item)"
                  >
                    <template #error>
                      <div class="image-slot">
                        <span>加载中</span>
                      </div>
                    </template>
                  </el-image>
                </div>
                <div class="topContent">
                  <p @click="GoToDetails(item)">{{ item.title }}</p>
                  <p>{{ item.html.address }}</p>
                </div>
              </div>
              <div class="content">
                <p v-html="item.html.scope"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="partBox" v-if="true">
          <div class="Industry Part">
            <div class="subTitle">
              <p>{{ state.config2.TypeList.typename }}</p>
              <p>{{ state.config2.TypeList.typenameE }}</p>
            </div>
            <div class="DisplayFlex">
              <div></div>
              <div class="SeeMore" @click="SeeMore2">
                <p>
                  <span>查看更多</span>
                  <span class="iconfont iconarrowright"></span>
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="state.tabInfo2.length > 0"
            class="TabInfo loading_areaProject"
          >
            <div
              v-for="(item, index) in state.tabInfo2"
              :key="index"
              class="TabInfo2Item"
            >
              <div class="TabItem">
                <div class="imageInfo">
                  <el-image
                    :src="item.img"
                    fit="cover"
                    style="width: 100%; height: 200px; display: block"
                  >
                    <template #error>
                      <div class="image-slot">
                        <span>加载中</span>
                      </div>
                    </template>
                  </el-image>
                </div>
                <div class="ContInfo">
                  <div class="Title">
                    <p>{{ item.title }}</p>
                  </div>
                  <div class="ContentInfo">
                    <span
                      >投资规模:
                      <span>{{ item.scale }}</span>
                      万元
                    </span>
                    <span
                      >项目类别:
                      <span>{{ state.levelOptions[item.level] }}</span></span
                    >
                  </div>
                </div>
              </div>
              <div class="ItemHover">
                <div>
                  <el-image
                    :src="item.img"
                    class="cuImage"
                    fit="cover"
                    style="
                      width: 100%;
                      height: 280px;
                      display: block;
                      border-radius: 4px;
                    "
                    @click="GoToDetailProject(item)"
                  >
                    <template #error>
                      <div class="image-slot">
                        <span>加载中</span>
                      </div>
                    </template>
                  </el-image>
                </div>
                <div class="HoverContent">
                  <div>
                    <p @click="GoToDetailProject(item)">{{ item.title }}</p>
                    <p>{{ item.enterprise ? item.enterprise.name : "" }}</p>
                  </div>
                  <div>
                    <p>
                      <span>投资规模: </span><span>{{ item.scale }}万元</span>
                    </p>
                    <p>
                      <span>已完成投资: </span
                      ><span>{{ item.invested }}万元</span>
                    </p>
                    <p>
                      <span>行业类别: </span
                      ><span>{{ item.cate ? item.cate.name : "" }}</span>
                    </p>
                    <p>
                      <span>项目所在地: </span
                      ><span>{{ item.city ? item.city.name : "" }}</span>
                    </p>
                    <p>
                      <span>项目类别: </span
                      ><span>{{ state.levelOptions[item.level] }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="copyRight">
          <div class="Industry"></div>
          <div class="StateInfo">
            <div class="StateInfoItem">
              <a href="http://extranet.badousoft.com:29999/whqyglxt/" target="_blank"
                ><img src="../img/1.jpg"
              /></a>
            </div>
            <div class="StateInfoItem">
              <a href="http://extranet.badousoft.com:29999/whqyglxt/" target="_blank"
                ><img src="../img/2.jpg"
              /></a>
            </div>
            <div class="StateInfoItem">
              <a href="http://extranet.badousoft.com:29999/whqyglxt/" target="_blank"
                ><img src="../img/3.jpg"
              /></a>
            </div>
            <div class="StateInfoItem">
              <a href="http://extranet.badousoft.com:29999/whqyglxt/" target="_blank"
                ><img src="../img/4.jpg"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
import api from "@/comment/request/api";
export default {
  name: "EnterpriseState",
  components: { },
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      specialInfo: {},
      config1: {
        TypeList: {
          typeid: 1,
          typename: "推荐企业",
          TypeIndex: 0,
          typenameE: "",
        },
        Data: [
          {
            id: 1,
            name: "上规上统",
          },
          {
            id: 2,
            name: "文化龙头",
          },
          {
            id: 3,
            name: "文化百强",
          },
          {
            id: 4,
            name: "三企入桂",
          },
          {
            id: 5,
            name: "小微企业",
          },
        ],
        value: "",
      },
      config2: {
        TypeList: {
          typeid: 2,
          typename: "推荐项目",
          TypeIndex: 0,
          typenameE: "",
        },
        Data: [
          {
            id: 1,
            name: "自治区级重点项目",
          },
          {
            id: 2,
            name: "地市级重点项目",
          },
        ],
        value: "",
      },
      tabInfo1: [],
      tabInfo2: [],
      levelOptions: ["其他项目", "区重点项目", "市重点项目", ""],
    });
    const GoToDetails = (item) => {
      router.push({
        name: "EnterpriseDetails",
        query: { id: item.enterprise_id },
      });
    };
    const GoToDetailProject = (item) => {
      router.push({
        name: "ProjectDetails",
        query: { id: item.project_id },
      });
    };
    /*获取企业数据*/
    const GetEnterpriseData = () => {
      let params = {
        page: 1,
        limit: 3,
      };
      api.GetEnterpriseList(params).success((res) => {
        state.tabInfo1 = res.data.data.data;
      });
    };
    GetEnterpriseData();
    // 项目
    const GetProjectData = () => {
      let params = {
        page: 1,
        limit: 3,
      };
      api.GetProjectList(params).success((res) => {
        state.tabInfo2 = res.data.data.data;
      });
    };
    GetProjectData();
    // 更多
    const SeeMore1 = () => {
      router.push({
        name: "Enterprise",
        query: {
          current: state.config1.value,
        },
      });
    };
    const SeeMore2 = () => {
      router.push({
        name: "Project",
        query: {
          current: state.config2.value,
        },
      });
    };
    // 横幅
    const GetTopic = () => {
      api
        .GetConfigs("home_banner1,home_banner1_t,home_banner1_u")
        .success((res) => {
          if (res.data.data) {
            state.specialInfo = res.data.data;
          }
        });
    };
    GetTopic();
    // 横幅详情
    const SpecialDetails = () => {
      if (state.specialInfo.home_banner1_t == 1) {
        window.open(state.specialInfo.home_banner1_u);
      } else if (state.specialInfo.home_banner1_t == 2)  {
        let routeUrl = router.resolve({
          name: "BottomCenter",
          query: {
            key: 4,
          },
        });
        window.open(routeUrl.href, "_blank");
      }
    };

    return {
      state,
      SpecialDetails,
      GoToDetails,
      GoToDetailProject,
      SeeMore1,
      SeeMore2,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";
.Box {
  padding-bottom: 15px;
}
.Industry.Part {
  border: 0;
  padding: 10px 0 0 0;
  .subTitle {
    font-size: 18px;
    font-weight: 700;
    padding-top: 6px;
  }
}
.TabInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .TabInfoItem {
    width: calc(100% / 3 - 10px);
    margin-top: 15px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    height: 240px;
    overflow: hidden;
    margin-right: 10px;
    //flex: 0 1 auto;
    .top {
      display: flex;
      background-color: #f4f4f4;
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      padding: 10px;

      > div {
        //flex: 1;
        width: 0;
      }

      > div:nth-child(1) {
        width: 100px;
        flex: 0 1 auto;
        font-size: 0;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
      }

      .topContent {
        /*div:nth-child(2)*/
        flex: 1 1 auto;
        margin-left: 10px;

        p:nth-child(1) {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          //font-weight: 600;
          color: #333333;
          min-height: 53px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          cursor: pointer;

          &:hover {
            color: #7f0404;
          }
        }

        p:nth-child(2) {
          margin-top: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .content {
      padding: 10px;
      background: #fff;
      //height: 160px;

      p {
        text-indent: 2em;
        font-size: 14px;
        line-height: 25px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
  }
  .TabInfoItem:last-child {
    margin-right: 0;
  }
  .TabInfo2Item {
    width: calc(100% / 3 - 10px);
    margin-top: 15px;
    margin-right: 10px;
    height: 280px;
    overflow: hidden;

    > div:nth-child(1) {
      height: 280px;
      //border: 1px solid red;
      .imageInfo {
        font-size: 0;
        width: 100%;
        height: 200px;

        /deep/ .el-image {
          img {
            border-radius: 4px 4px 0 0;
          }
        }
      }

      .ContInfo {
        border-radius: 0 0 4px 4px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: #fff;
        height: 80px;

        .Title {
          width: calc(100% - 20px);
          margin: 0 auto;
          text-align: center;
          padding: 10px 0;
          border-bottom: 1px dashed #d8d8d8;

          p {
            font-size: 18px;
            line-height: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            //font-weight: 600;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .ContentInfo {
          width: calc(100% - 20px);
          margin: 0 auto;
          padding: 8px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #505356;

            span {
              color: #7f0404;
            }
          }

          > span:nth-child(2) {
            float: right;
          }
        }
      }
    }

    .ItemHover {
      height: 280px;
      position: relative;
      //display: none;
      margin-top: 10px;

      .HoverContent {
        margin: 20px;
        border: 5px solid #7f0404;
        background: rgba(255, 255, 255, 0.9);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2;

        > div:nth-child(1) {
          text-align: center;

          p:nth-child(1) {
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            //font-weight: 600;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 20px 10px 10px 10px;
            cursor: pointer;
          }

          p:nth-child(1):hover {
            color: #7f0404;
          }

          p:nth-child(2) {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7f0404;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 10px 10px 10px;
          }
        }

        > div:nth-child(2) {
          p {
            font-size: 14px;
            line-height: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #505356;
            padding: 5px 10px;

            span:nth-child(1) {
              width: 85px;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            span:nth-child(2) {
              width: calc(100% - 85px);
              color: #7f0404;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  .TabInfo2Item:last-child {
    margin-right: 0;
  }
  .TabInfo2Item:hover {

    .ItemHover {
      animation: transTop 1s forwards;
      /* Safari 与 Chrome: */
      -webkit-animation: transTop 1s forwards;
    }
  }
}
@keyframes transTop {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-290px);
  }
}
.SeeMore {
  padding-left: 20px;
}

.copyRight {
  .Industry {
    border: none;
  }
  .StateInfoItem {
    width: 100%;
    margin-bottom: 35px;
    img {
      width: 100%;
    }
  }
}

.contentBox {
  display: flex;

  > div {
    &:nth-child(1) {
      flex: 1;
    }

    &:nth-child(2) {
      width: 320px;
      margin-left: 40px;
    }
  }
}

.SpecialBox {
  margin-top: 40px;
  position: relative;

  > div:nth-child(1) {
    cursor: pointer;
  }

  > div:nth-child(2) {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #7f0404;
    padding: 10px 20px;
    cursor: pointer;
    display: none;

    p {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
</style>