<template>
  <div class="Box">
    <div class="limitBox">
      <div
        class="Industry"
        style="display: flex; justify-content: space-between"
      >
        <div class="mainTitle">
          <p>地方资讯</p>
          <p>Local Consultation</p>
        </div>
        <div class="SeeMore">
          <p @click="SeeMore">
            <span>查看更多</span><span class="iconfont iconarrowright"></span>
          </p>
        </div>
      </div>
      <SearchAddress @getCurrentId="getCurrentId" />
      <div class="mainBox loading_areaLoacl">
        <div v-for="(item, index) in state.dataList" :key="index">
          <div class="time cuImage" @click="GoToDetails(item)">
            <div v-if="item.img">
              <el-image
                :src="item?.img"
                fit="cover"
                style="width: 100px; height: 80px"
              >
                <template #error>
                  <div class="image-slot">
                    <span>加载中</span>
                  </div>
                </template>
              </el-image>
            </div>
            <div v-else>
              <el-image
                :src="require('../img/logo.png')"
                fit="contain"
                style="
                  width: 100px;
                  height: 80px;
                  background-color: transparent;
                "
              >
                <template #error>
                  <div class="image-slot">
                    <span>加载中</span>
                  </div>
                </template>
              </el-image>
            </div>
          </div>
          <div class="content">
            <div>
              <p @click="GoToDetails(item)">{{ item.title }}</p>
            </div>
            <div>
              <!-- <p>
                <span
                  class="icon iconfont iconkejian"
                  :style="{ marginRight: '5px' }"
                ></span
                >{{ item.views }}
              </p> -->
              <p>
                <span
                  class="icon iconfont iconriqixuanze"
                  :style="{ marginRight: '5px' }"
                ></span
                >{{ item?.add_time.split(" ")[0] }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import api from "@/comment/request/api";
import SearchAddress from "../../../components/SearchAddress/SearchAddress.vue";
import { useRouter } from "vue-router";
export default {
  components: { SearchAddress },
  setup(props) {
    const state = Vue.reactive({
      code: '',
      dataList: [],
    });
    const router = useRouter();
    const getCurrentId = (code) => {
      state.code = code;
      getDataList();
    };
    const GoToDetails = (item) => {
      router.push({
        name: "LocalDetails",
        query: {
          id: item.local_id,
        },
      });
    };
    const getDataList = () => {
      api.GetLocalList({ limit: 6, code: state.code }).success((res) => {
        state.dataList = res.data.data.data;
      });
    };
    getDataList();
    const SeeMore = () => {
      router.push({
        name: "Local",
      });
    };
    return {
      state,
      getCurrentId,
      GoToDetails,
      SeeMore,
    };
  },
};
</script>

<style lang="scss" scoped>
.Industry {
  border: none;
  > div {
    float: none !important;
    p {
      text-align: center;
      &:nth-child(2) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
.mainBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    width: 48%;
    display: flex;
    margin-bottom: 20px;
    .time {
      width: 100px;
      height: 80px;
      //border: 4px solid #7f0404;
      //border-radius: 4px;
      p {
        text-align: center;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        //font-weight: 600;
        color: #7f0404;
        line-height: 24px;
        padding: 6px 0;
        &:nth-child(2) {
          font-size: 18px;
          color: #fff;
          background: #7f0404;
          padding: 10px 0;
        }
      }
      /deep/img {
        display: block;
      }
    }
    .content {
      margin-left: 10px;
      flex: 1;
      border-bottom: 1px solid #d8d8d8;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > div:nth-child(1) {
        p {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          //font-weight: 600;
          color: #333333;
          line-height: 24px;
          &:nth-child(1) {
            cursor: pointer;
            &:hover {
              color: #7f0404;
            }
          }
        }
      }
      > div:nth-child(2) {
        display: flex;
        justify-content: flex-start;
        p {
          color: #666666;
          font-size: 12px;
          line-height: 12px;
          margin-bottom: 12px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>