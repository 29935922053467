<template>
  <div class="Box">
    <div class="FileList limitBox">
      <div style="padding-right: 28px;">
        <div style="border-radius: 10px;border: 2px solid #7F0304;padding: 25px 30px;position: relative;overflow: hidden;cursor: pointer;" @click="toUrl(1)">
          <div style="font-size: 16px;font-weight: 600;display: flex;align-items: center;">
            <div style="flex: 1;">文化产业企业管理平台</div>
            <span class="iconfont iconarrowright"></span>
          </div>
          <div class="line1" style="margin-top: 15px;font-size: 16px;">文化产业企业管理平台</div>
          <div class="background1"></div>
        </div>
      </div>
      <div style="padding: 0 22px;">
        <div style="border-radius: 10px;border: 2px solid #7F0304;padding: 25px 30px;position: relative;overflow: hidden;cursor: pointer;" @click="toUrl(2)">
          <div style="font-size: 16px;font-weight: 600;display: flex;align-items: center;">
            <div style="flex: 1;">文化产业项目管理平台</div>
            <span class="iconfont iconarrowright"></span>
          </div>
          <div class="line1" style="margin-top: 15px;font-size: 16px;">文化产业项目管理平台</div>
          <div class="background2"></div>
        </div>
      </div>
      <div style="padding: 0 22px;">
        <div style="border-radius: 10px;border: 2px solid #7F0304;padding: 25px 30px;position: relative;overflow: hidden;cursor: pointer;" @click="toUrl(3)">
          <div style="font-size: 16px;font-weight: 600;display: flex;align-items: center;">
            <div style="flex: 1;">文化产业资源管理平台</div>
            <span class="iconfont iconarrowright"></span>
          </div>
          <div class="line1" style="margin-top: 15px;font-size: 16px;">文化产业资源管理平台</div>
          <div class="background1"></div>
        </div>
      </div>
      <div style="padding-left: 28px;">
        <div class="hover1" style="border-radius: 10px;border: 2px solid #7F0304;cursor: pointer;position: relative;">
          <div style="padding: 25px 30px;position: relative;overflow: hidden;">
            <div style="font-size: 16px;font-weight: 600;display: flex;align-items: center;">
              <div style="flex: 1;">文化产业资源运营平台</div>
              <span class="iconfont iconarrowright"></span>
            </div>
            <div class="line1" style="margin-top: 15px;font-size: 16px;">文化产业资源运营平台</div>
            <div class="background2"></div>
          </div>
          <div class="hover-play" style="width: 100%;position: absolute;top: -110px;left: 0;padding-bottom: 50px;">
            <div style="background: #FFF;box-shadow: 0px 1px 10px 0px rgba(194,194,194,0.5);text-align: center;font-size: 16px;font-weight: 400;color: #303133;">
              <div style="padding: 13px 0;" class="menu-hover" @click="toUrl(4)">广文e版权</div>
              <div style="padding: 13px 0;border-top: 1px solid #E7E6E4;" class="menu-hover" @click="toUrl(5)">广文数博</div>
              <!-- <div style="padding: 13px 0;border-top: 1px solid #E7E6E4;" class="menu-hover" @click="toUrl(6)">广文文化数字资产交易平台</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";

export default {
  name: "Plats",
  components: { },
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
    });
    // 详情
    const toUrl = (type) => {
      let url = '';
      if (type == 1) url = 'http://extranet.badousoft.com:29999/whqyglxt/';
      if (type == 2) url = 'http://extranet.badousoft.com:29999/whqyglxt/';
      if (type == 3) url = 'http://www.gxwhcy.com/admin#/adm/industry/resource';
      if (type == 4) url = 'https://gwbq.gxwhcy.com/blockChain.html';
      if (type == 5) url = 'https://gwsb.sgwa.com.cn/';
      if (type == 6) url = 'https://gwbq.gxwhcy.com/';
      window.open(url, '_blank');
    };
    return {
      state,
      toUrl
    };
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
@import "/src/comment/comment";

.line1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-hover:hover {
  color: #8F0909;
}
.hover-play {
  display: none;
}
.hover1:hover .hover-play {
  display: block;
}

.Box {
  background: #fff;
  .background1 {
    width: 330px;
    height: 330px;
    position: absolute;
    left: -150px;
    top: 50%;
    transform: translateY(-50%);
    background: url("../img/redBg1.png");
    background-size: cover;
    opacity: 0.1;
  }
  .background2 {
    width: 330px;
    height: 330px;
    position: absolute;
    right: -150px;
    top: 50%;
    transform: translateY(-50%);
    background: url("../img/redBg1.png");
    background-size: cover;
    opacity: 0.1;
  }
}
.FileList {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #D8D8D8;
  color: #7F0304;
  > div {
    width: 25%;
  }
}
</style>